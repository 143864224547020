import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
  faChevronUp,
  faRss,
  faBars,
  faTimes,
  faAngleDoubleRight,
  faCircle,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faLocation,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faPlay,
  faArrowCircleRight,
  faArrowCircleLeft,
  faSearch,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedin,
  faLinkedinIn,
  faSlideshare,
  faGithub,
  faPinterest,
  faInstagram,
  faGooglePlusG,
} from '@fortawesome/free-brands-svg-icons';

export const fa = () => {
  library.add(
    faChevronUp,
    faRss,
    faBars,
    faTimes,
    faAngleDoubleRight,
    faCircle,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faPlay,
    faArrowCircleRight,
    faArrowCircleLeft,
    faSearch,
    faFacebookF,
    faTwitter,
    faYoutube,
    faLinkedin,
    faLinkedinIn,
    faSlideshare,
    faGithub,
    faPinterest,
    faInstagram,
    faGooglePlusG,
    faLocation,
    faUser,
  );
  dom.watch();
};
