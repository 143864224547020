export const header = $ => {
  function openMobileMenu() {
    $('#nav-links').addClass('menu-open');
    $('body').addClass('menu-open');
  }

  function closeMobileMenu() {
    $('#nav-links').removeClass('menu-open');
    $('body').removeClass('menu-open');
  }

  $('button.mobile-menu-open').click(function() {
    if (!$('#nav-links').hasClass('menu-open')) {
      openMobileMenu();
    }
  });

  $('button.close-menu').click(function() {
    if ($('#nav-links').hasClass('menu-open')) {
      closeMobileMenu();
    }
  });

  $(document).click(function(e) {
    if (
      ((!$(e.target).closest('#nav-links').length &&
        !$(e.target).closest('.mobile-menu').length) ||
        $(e.target).closest('#nav-links a').length) &&
      $('#nav-links').hasClass('menu-open')
    ) {
      closeMobileMenu();
    }
  });
};
