import $ from 'jquery';

import './styles.scss';

import { fa } from './src/fa';
import { returntotop } from './src/returntotop';
import { header } from './src/header';
// import { venuecollection } from './src/venuecollection';
import { affix } from './src/affix';
import { headeraffix } from './src/headeraffix';

$(() => {
  const options = {
    screen_sm_min: 768,
    screen_sm_max: 767,
  };

  fa();
  affix($);
  returntotop($);
  header($);
  // venuecollection($);
  headeraffix($);
});
